export const validateWalletData = (data) => {
  const addresses = new Set();
  const identification = new Set();

  if (data?.length < 5) {
    return "At least 5 wallets and identifications are required.";
  }

  for (const wallet of data) {
    if (!wallet?.walletAddress || !wallet?.identification) {
      return "Wallet address and Identification cannot be empty.";
    }
    if (wallet?.walletAddress?.length !== 41) {
      return "Wallet address must be exactly 41 characters long.";
    }
    if (!wallet?.walletAddress?.startsWith("mx")) {
      return "Invalid Wallet Address, address must start with 'mx'.";
    }
    if (
      wallet?.identification?.length < 3 ||
      wallet?.identification?.length > 20
    ) {
      return "Identification must be at least 3 characters and max 20 characters long.";
    }

    // Normalize wallet address to lowercase for uniqueness check
    const normalizedAddress = wallet.walletAddress.toLowerCase();
    if (addresses.has(normalizedAddress)) {
      return "Wallet addresses must be unique.";
    }

    // Normalize identification as well, if you want to apply the same rule
    const normalizedIdentification = wallet.identification.toLowerCase();
    if (identification.has(normalizedIdentification)) {
      return "Identifications must be unique.";
    }

    addresses.add(normalizedAddress);
    identification.add(normalizedIdentification);
  }
  return null;
};
