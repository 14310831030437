import moment from "moment";
import { handleCreateEventValidations } from "./handleCreateEventValiadtions";
import { InTableDateFormat, messages } from "../constant/constants";
import { timeStampToDate } from "./commonTimeFunctions";
import { env } from "../constant/envConstant";

const trimAddressLength = (address = "", startLen = 7, endLen = 7) => {
  if (typeof address !== "string" || !address) return "-";
  return `${address.substring(0, startLen)}...${address.substring(
    address.length - endLen,
    address.length
  )}`;
};

export default trimAddressLength;

// function to check and set errors in state
export const errorSetterFunction = (data) => {
  let newErr = {};
  for (let key in data) {
    newErr = {
      ...newErr,
      ...handleCreateEventValidations(key, data[key]),
    };
  }
  return newErr;
};

export const toPrecise = (number, precision) => {
  if (!number) return "0";
  let originalString = number?.toString();
  if (!originalString?.includes(".")) {
    return originalString;
  }
  let decimalIndex = originalString?.indexOf(".");
  let truncatedString = originalString?.substring(
    0,
    decimalIndex + (precision + 1)
  );
  if (truncatedString?.endsWith(".00")) {
    truncatedString = truncatedString?.slice(0, -(precision + 1));
  }
  return truncatedString;
};

export const CapitalizeFirstLetter = (str) => {
  if (!str) return "-";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const eventNameHandler = (coinName, price, targetDate) => {
  let eventTitle = `${coinName || "-"} to be priced at ${
    price || 0
  } USDT or more as on${" "}${moment
    .unix(targetDate)
    .format(InTableDateFormat)}?`;
  return eventTitle;
};

export const convertLeadingZeros = (value) => {
  if (value === "") return "";
  if (value.includes(".")) {
    let [integerPart, decimalPart] = value.split(".");
    integerPart = String(Number(integerPart));
    return integerPart === "0"
      ? `0.${decimalPart}`
      : `${integerPart}.${decimalPart}`;
  } else {
    return String(Number(value));
  }
};

export const checkEventEditable = (date) => {
  return (
    moment().diff(moment(timeStampToDate(date)), env.editInterval) <
    env.editTime
  );
};

export const getCurrencyIconUrl = (cryptoList, symbol) => {
  const selectedCrypto = cryptoList?.find(
    (crypto) => crypto?.symbol === symbol
  );
  return selectedCrypto;
};

export const formatMillionNumber = (num) => {
  let formattedNumber;

  switch (true) {
    case num >= 1e12:
      formattedNumber = (num / 1e12).toFixed(4) + " T";
      break;
    case num >= 1e9:
      formattedNumber = (num / 1e9).toFixed(4) + " B";
      break;
    case num >= 1e6:
      formattedNumber = (num / 1e6).toFixed(4) + " M";
      break;
    case num >= 1e3:
      formattedNumber = (num / 1e3).toFixed(4) + " K";
      break;
    default:
      formattedNumber = +num?.toFixed(4);
      break;
  }

  return formattedNumber;
};

export const acceptedFormat = {
  imgFileTypes: [".svg"],
};

export const handleImgUpload = ({ e, maxSize }) => {
  try {
    const decideSize = maxSize ? maxSize : 1.2;
    const errorMessage = maxSize
      ? messages.faultImgRaiseDispute
      : messages.faultImg;

    const file = e?.file || e;
    if (file) {
      const localURL = URL.createObjectURL(file?.originFileObj);
      if (!(file?.size / 1024 / 1024 > decideSize)) {
        return {
          error: false,
          data: localURL,
        };
      } else {
        return {
          error: true,
          msg: errorMessage,
        };
      }
    } else return false;
  } catch (error) {
    console.error(error);
    return {
      error: false,
      msg: error,
    };
  }
};

export const constructQueryString = (params) => {
  const query = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value || value === 0) {
      query.append(key, value);
    }
  });
  return `?${query.toString()}`;
};
