import React from "react";
import { TokenAddSuccessfullyIcon } from "../../../assets/svgImages/StoreAsset";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";

function SuccessToken({ onClose, symbol }) {
  return (
    <div className="successToken">
      <TokenAddSuccessfullyIcon />
      <h2>Token Added Successfully </h2>
      <p>
        Token Name = <span>{symbol}</span>
      </p>
      <ButtonCustom label="Done" onClick={onClose} />
    </div>
  );
}

export default SuccessToken;
