import React from "react";
import { Dropdown, Space } from "antd";
import "./Token.scss";
import {
  ArrowUp,
  PlusIcon,
  SortIcon,
} from "../../../assets/svgImages/StoreAsset";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";

const Header = ({ metaData, setMetaData, toggleAddModal }) => {
  const statusItems = [
    {
      label: "All",
      key: "0",
      value: "all",
    },
    {
      label: "Active",
      key: "1",
      value: "active",
    },
    {
      label: "Inactive",
      key: "2",
      value: "inactive",
    },
    {
      label: "Paused",
      key: "3",
      value: "pause",
    },
  ];

  const sortItems = [
    {
      label: "New to Old",
      key: "0",
      value: "desc",
    },
    {
      label: "Old to New",
      key: "1",
      value: "asc",
    },
  ];

  const props = {
    filter: {
      items: statusItems,
      selectable: true,
      defaultSelectedKeys: [metaData?.statuskey],
      onClick: (item) => {
        handleChange(statusItems, item, "status", "statuskey");
      },
    },
    sort: {
      items: sortItems,
      selectable: true,
      defaultSelectedKeys: [metaData?.sortkey],
      onClick: (item) => {
        handleChange(sortItems, item, "sort", "sortkey");
      },
    },
  };

  const handleChange = (items, item, value, key) => {
    setMetaData((prev) => ({
      ...prev,
      page: 1,
      [value]: items[Number(item?.key)]["value"],
      [key]: item?.key,
    }));
  };

  return (
    <div className="token_top">
      <p>Total = {metaData?.total}</p>
      <div className="rightSideItems">
        {/* filter */}
        <Dropdown
          className="customDropdown"
          menu={props.filter}
          trigger={["click"]}
        >
          <Space>
            <div className="customContainer">
              <SortIcon />
              {metaData?.statuskey
                ? statusItems[Number(metaData?.statuskey)]["label"]
                : "Filter"}
            </div>

            <div className="arrowUp">
              <ArrowUp />
            </div>
          </Space>
        </Dropdown>
        {/* sorting */}
        <Dropdown
          className="customDropdown"
          menu={props.sort}
          trigger={["click"]}
        >
          <Space>
            <div className="customContainer">
              <SortIcon />
              {metaData?.sortkey
                ? sortItems[Number(metaData?.sortkey)]["label"]
                : "Sort"}
            </div>

            <div className="arrowUp">
              <ArrowUp />
            </div>
          </Space>
        </Dropdown>
        <ButtonCustom
          className="customBtnToken"
          label="Add"
          onClick={toggleAddModal}
        >
          <PlusIcon />{" "}
        </ButtonCustom>
      </div>
    </div>
  );
};

export default Header;
