import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import {
  InTableDateFormat,
  targetClosureDateFormat,
} from "../constant/constants";

dayjs.extend(customParseFormat);

export const handleTargetDateChange = (targetDate, formData) => {
  const { gapInDays, gapInHours } = getDaysAndHours(targetDate);
  const minsToMinusForBetting = 5;
  let newClosureDate = modifyDate(
    targetDate,
    minsToMinusForBetting,
    "minusMins"
  );

  return {
    ...formData,
    betClosureTime: newClosureDate || formData?.betClosureTime,
    eventDurationDays: gapInDays,
    eventDurationHours: gapInHours,
    targetDate: targetDate,
  };
};

// Calculate the Event Duration in Hours & Days
export const getDaysAndHours = (target) => {
  const startTime = new Date();
  // Extract the Date object if target is an object with a $d property
  const endTime =
    target && target.$d ? new Date(target.$d) : timeStampToDate(target);

  let gapInDays = 0;
  let gapInHours = 0;
  // Calculate the difference in milliseconds
  const differenceInMs = endTime - startTime;

  // Check if endTime is after startTime
  if (differenceInMs > 0) {
    // Convert milliseconds to days and hours
    const millisecondsInDay = 86400000; // 1000 ms * 60 s * 60 m * 24 h
    const millisecondsInHour = 3600000; // 1000 ms * 60 s * 60 m

    gapInDays = Math.floor(differenceInMs / millisecondsInDay);
    gapInHours = Math.floor(
      (differenceInMs % millisecondsInDay) / millisecondsInHour
    );
  }

  return {
    gapInDays,
    gapInHours,
  };
};

export const antdTimeFormat = (date, value, operation) => {
  return dayjs(dayJsTimeFormat(modifyDate(date, value, operation))).format(
    targetClosureDateFormat
  );
};

export const dayJsTimeFormat = (given_date) => {
  const date = new Date(given_date) || new Date();
  const formattedDate =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    ("0" + date.getHours()).slice(-2);
  return formattedDate;
};

export const modifyDate = (date, value, operation) => {
  switch (operation) {
    case "addHours":
      return dayjs(dayjs(date).add(value, "hour"))
        .add(date.getMinutes() ? 1 : 0, "hour")
        .minute(0)
        .second(0);
    case "minusHours":
      return dayjs(date).subtract(value, "hour");
    case "minusMins":
      return dayjs(date).subtract(value, "minutes");
    case "addDays":
      return dayjs(date).add(value, "days");
    default:
      return dayjs();
  }
};
// Generate Timestamp
export const getTimeStamp = (time) => {
  const startOfDay = moment(new Date(time)).startOf("day").unix();
  const hours = new Date(time).getHours() * 3600; // Convert hours to seconds
  const minutes = new Date(time).getMinutes() * 60; // Convert minutes to seconds
  const seconds = new Date(time).getSeconds();
  const exactTimeStamp = startOfDay + hours + minutes + seconds;
  return exactTimeStamp;
};

export const convertToDateTime = (date) => {
  let dateObj = new Date(date);
  let dates;
  if (isNaN(dateObj)) {
    dates = Number(date);
  } else {
    dates = dateObj.getTime();
  }
  let timeLeft = moment(dates).format(InTableDateFormat);
  return timeLeft;
};

export const defTargetDate = antdTimeFormat(new Date(), 24, "addHours");
export const defClosureDate = antdTimeFormat(
  new Date(defTargetDate),
  12,
  "minusHours"
);

// Date Picker's Disable Date & Time Methods
export const minDateTime = defTargetDate; //min date & time, 1 hours from today's date
export const maxDateTime = dayjs().add(365, "day"); //max date & time, 365 days from today's date

//Disable Date
export const disabledDate = (current, minDate) => {
  return (
    current &&
    (current < minDate.startOf("day") || current > maxDateTime.endOf("day"))
  );
};

// Disable Hours
export const disabledDateTime = (current, minDate) => {
  if (!current) {
    return {};
  }

  const isSameMinDay = current.isSame(minDate, "day");
  const isSameMaxDay = current.isSame(maxDateTime, "day");
  const disabledHours = () => {
    if (isSameMinDay) {
      return Array.from({ length: 24 }, (_, i) => i).filter(
        (hour) => hour < minDate.hour()
      );
    }
    if (isSameMaxDay) {
      return Array.from({ length: 24 }, (_, i) => i).filter(
        (hour) => hour > maxDateTime.hour()
      );
    }
    return [];
  };
  return {
    disabledHours,
  };
};

export const timeStampToDate = (date) => {
  if (!date) return;
  const reg = new RegExp(/^\d+$/);
  if (reg.test(date)) return new Date(date * 1000);
  return new Date(date);
};

export const convertTimeStampIntoHoursMinutes = (timesatmp) => {
  const duration = moment.duration(timesatmp, "seconds");

  // Calculate days and hours
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours() % 24); // Hours remaining after removing full days

  return { hours, days };
};
