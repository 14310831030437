import React, { useEffect, useState } from "react";
import { message } from "antd";
import UseGetApi from "../../../hooks/useGetApi";
import InputCustom from "../../InputCustom/InputCustom";
import { apiUrls } from "../../../constant/apiConstants";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { contractEvents } from "../../../utils/contractHelpers";
import { createInputs } from "../../../constant/structuralContants";
import { validateWalletData } from "../../../helpers/handleValidations";
import {
  addressRegex,
  adminEventNames,
  apiMethods,
} from "../../../constant/constants";

function Arbitration() {
  const walletInputs = createInputs("wallet");
  const identificationInputs = createInputs("identification");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [walletData, setWalletData] = useState(
    Array(5).fill({ walletAddress: "", identification: "", key: "" })
  );

  const handleWalletChange = (e, index) => {
    const { name, value } = e.target;
    const normalizedValue =
      name === "walletAddress" ? value.toLowerCase() : value;
    if (addressRegex.test(normalizedValue) || normalizedValue === "") {
      setWalletData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [name]: normalizedValue,
          key: index,
        };
        return newData;
      });
    }
  };

  // Contract handler for adding or editing wallets
  const handleWalletsContractCall = async () => {
    const addresses = walletData?.map((wallet) => wallet?.walletAddress);
    const res = await contractEvents({
      eventName: adminEventNames.addFallbackWallet,
      wallets: addresses,
    });
    return res;
  };

  // API handler for adding wallets & identification
  const addWalletsAndNames = async () => {
    const data = await UseGetApi(
      apiUrls.addWalletsAndNames,
      apiMethods.POST,
      walletData
    );
    return data?.data;
  };

  // API handler for editing wallets & identification
  const editWalletsAndNames = async () => {
    const data = await UseGetApi(
      apiUrls.editWalletsAndNames,
      apiMethods.PUT,
      walletData
    );
    return data?.data;
  };

  // Call contract & API methods
  const handleAddClick = async () => {
    try {
      // firstly, validate wallet data
      setIsLoading(true);
      if (!walletData?.length || walletData.length < 5) {
        message.error(
          "At least 5 wallet addresses and identifications are required."
        );
        return;
      }
      const validationError = validateWalletData(walletData);
      if (validationError) {
        message.error(validationError);
        return;
      }

      //  call a function based on edit state
      const handleWalletAction = isEditable
        ? editWalletsAndNames
        : addWalletsAndNames;

      // Call the needed function (either add or edit)
      const response = await handleWalletAction();

      if (response) {
        // Handle the contract call if the wallet action is successful
        const res = await handleWalletsContractCall();
        if (res?.length) {
          message.success(response?.message);
          getWallets();
        }
      }
    } catch (err) {
      message.error(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  // get wallets
  const getWallets = async () => {
    try {
      const { data } = await UseGetApi(
        apiUrls.getFallbackWallets,
        apiMethods.GET
      );
      const wallets = data?.data?.map((item) => ({
        walletAddress: item?.walletAddress,
        identification: item?.identification,
        key: item?.key,
      }));
      const isData = wallets?.some((wallet) => wallet?.walletAddress);
      setIsEditable(isData);
      setWalletData(wallets);
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getWallets();
  }, []);

  return (
    <div className="arbitrationInputMulti">
      <div className="arbitrationInput">
        <div className="fallbackWalletData">
          {walletInputs?.map((item, index) => (
            <InputCustom
              key={index}
              placeholder={item.placeholder}
              label={item.label}
              regularInput
              name="walletAddress"
              value={walletData[index]?.walletAddress}
              onChange={(e) => handleWalletChange(e, index)}
            />
          ))}
        </div>
        <div className="fallbackWalletData">
          {identificationInputs?.map((item, index) => (
            <InputCustom
              key={index}
              placeholder={item.placeholder}
              label={item.label}
              regularInput
              name="identification"
              value={walletData[index]?.identification}
              onChange={(e) => handleWalletChange(e, index)}
            />
          ))}
        </div>
      </div>
      <div className="addButton">
        <ButtonCustom
          label={isEditable ? "Edit Wallets" : "Add Wallets"}
          onClick={handleAddClick}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default Arbitration;
