import React from "react";
import "./Login.scss";
import logo from "../../../assets/Logo.svg";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import useWalletConnection from "../../../hooks/useWalletConnection.jsx";

function Login() {
  const { isDisabled, addNetwork, isLoading } = useWalletConnection();

  return (
    <div className="loginBoard">
      <div className="loginBoard_left"></div>
      <div className="loginBoard_right">
        <div className="loginBoard_right_content">
          <div className="loginBoard_right_content_logo">
            <img src={logo} alt="logo" />
          </div>
          <h3>Admin Panel</h3>
          <div className="loginBoard_ right_content_input"></div>
          <div className="spacing_top-20">
            <ButtonCustom
              label="Connect Wallet"
              disabled={isDisabled}
              onClick={() => addNetwork()}
              isLoading={isLoading}
              isDisabled={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
