import React, { useState } from "react";
import { message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import InputCustom from "../../InputCustom/InputCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { UploadIcon } from "../../../assets/svgImages/StoreAsset";
import CustomModal from "../../customModal/CustomModal";
import SuccessToken from "./SuccessToken";
import {
  acceptedFormat,
  handleImgUpload,
} from "../../../helpers/commonHelpers";
import UseGetApi from "../../../hooks/useGetApi";
import { apiUrls } from "../../../constant/apiConstants";
import { alphabets, alphabetsOnly } from "../../../constant/constants";
import { env } from "../../../constant/envConstant";

function AddToken({ formData, setFormData, onSubmit, setLoading, loading }) {
  const [addTokenModal, setaddTokenModal] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [errors, setErrors] = useState({});

  const toggleSuccessModal = (closeAddModal) => {
    setaddTokenModal(!addTokenModal);
    closeAddModal === true && onSubmit();
  };

  const validate = (formData) => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Token name is required.";
    else if (formData.name.length < 3)
      newErrors.name = "Token name must have minimum length of 3.";
    else if (formData.name.length > 100)
      newErrors.name = "Token name must have maximum length of 100.";
    else if (!alphabetsOnly.test(formData.name))
      newErrors.name = "Token name must contain only alphabets.";

    if (!formData.symbol) newErrors.symbol = "Symbol is required.";
    else if (formData.symbol.length > 10)
      newErrors.symbol = "Symbol must have maximum length of 10.";
    else if (!alphabets.test(formData.symbol))
      newErrors.symbol = "Symbol must contain only alphabets.";

    if (!formData.file) newErrors.file = "Icon is required.";

    if (!formData.isSubmitting) {
      return formData.hasOwnProperty(Object.keys(formData)[0])
        ? {
            [Object.keys(formData)[0]]: newErrors[Object.keys(formData)[0]],
          }
        : {};
    }
    return newErrors;
  };

  const addToken = async () => {
    setLoading(true);

    const validationErrors = validate({ ...formData, isSubmitting: true });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      const formdata = new FormData();
      formdata.append("name", formData?.name);
      formdata.append("symbol", formData?.symbol);
      formData?.file?.name &&
        formdata.append("file", formData?.file, formData?.file?.name);

      const verifyNameResult = await UseGetApi(
        apiUrls.verifyToken({
          slug: formData?.name,
        }),
        null,
        null,
        {
          api_key: env.cmcKey,
          Accept: "application/json",
        },
        true
      );
      if (verifyNameResult) {
        const verifySymbolResult = await UseGetApi(
          apiUrls.verifyToken({
            symbol: formData?.symbol,
          }),
          null,
          null,
          {
            api_key: env.cmcKey,
            Accept: "application/json",
          },
          true
        );
        if (verifySymbolResult) {
          const response = await UseGetApi(
            apiUrls?.addToken(),
            "POST",
            formdata
          );
          if (response?.data?.status === 200) {
            toggleSuccessModal();
          }
        } else {
          message.error({
            content: "Token name or symbol doesn't exist on 'Coin Market Cap'.",
            key: "error",
          });
        }
      } else {
        message.error({
          content: "Token name or symbol doesn't exist on 'Coin Market Cap'.",
          key: "error",
        });
      }
    }

    setLoading(false);
  };

  const handleFileUpload = async (file, fileList) => {
    try {
      if (fileList[0]) {
        const response = await handleImgUpload({
          e: { file: { originFileObj: file, size: file?.size } },
          maxSize: null,
        });

        if (response?.error) {
          message.error({
            content: response?.msg,
            key: "error",
          });

          setProfileImg(profileImg);
          setFormData((prev) => ({ ...prev, file: profileImg }));
          return false;
        } else {
          const errors = validate({
            file,
          });

          setErrors(errors);
          setProfileImg(response?.data);
          setFormData((prev) => ({ ...prev, file: file }));
          return true;
        }
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleChange = (event, key) => {
    const errors = validate({
      [key]: event.target.value,
    });

    setErrors(errors);
    setFormData({ ...formData, [key]: event.target.value });
  };

  return (
    <div className="addToken">
      <div className="plateformContent">
        <InputCustom
          regularInput
          label="Token Name"
          placeholder="Enter Token Name"
          id="fee"
          onChange={(event) => {
            handleChange(event, "name");
          }}
        />
        {errors?.name && <p className="error_red">{errors?.name}</p>}
        <InputCustom
          regularInput
          label="Symbol"
          placeholder="Enter Symbol"
          id="fee"
          onChange={(event) => {
            handleChange(event, "symbol");
          }}
        />
        {errors?.symbol && <p className="error_red">{errors?.symbol}</p>}

        <div className="token_uploadContainer">
          <label className="selectStyleLabel">Add Icon</label>
          <Dragger
            className="uploadCustom"
            accept={acceptedFormat?.imgFileTypes?.toString()}
            showUploadList={false}
            beforeUpload={handleFileUpload}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess("ok");
              }, 0);
            }}
          >
            {profileImg ? (
              <img src={profileImg} alt="" height="100%" width="100%" />
            ) : (
              <UploadIcon />
            )}
          </Dragger>
        </div>
        {errors?.file && <p className="error_red">{errors?.file}</p>}

        <div className="plateformContent__button">
          <ButtonCustom
            label="Add Token"
            onClick={() => addToken()}
            isDisabled={loading}
            isLoading={loading}
          />
        </div>
      </div>
      <CustomModal
        visible={addTokenModal}
        onClose={() => toggleSuccessModal(true)}
        title={false}
        content={
          <SuccessToken
            onClose={() => toggleSuccessModal(true)}
            symbol={formData?.symbol}
          />
        }
        width={514}
      />
    </div>
  );
}

export default AddToken;
