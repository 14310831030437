import React from "react";
import { TokenAddSuccessfullyIcon } from "../../../assets/svgImages/StoreAsset";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";

function ConfirmToken({ onClose, symbol, onRemove, action }) {
  return (
    <div className="successToken">
      <TokenAddSuccessfullyIcon />
      <h2>
        Confirm Token{" "}
        {action === "Active"
          ? "Activation"
          : action === "Deactivate"
          ? "Deactivation"
          : action}
        ?
      </h2>
      <p>
        Are you sure you want to {action.toLowerCase()} <span>{symbol}</span>{" "}
        from the platform?
      </p>
      <div>
        <ButtonCustom btnBorder label="Cancel" onClick={onClose} />
        <ButtonCustom label={action} onClick={onRemove} />
      </div>
    </div>
  );
}

export default ConfirmToken;
