import React from "react";
import "./Activity.scss";
import ActivityTable from "./ActivityTable.jsx";
function Activity() {
  return (
    <div className="activityContainer">
      <ActivityTable />
    </div>
  );
}

export default Activity;
