import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "./CreateEvent.scss";
import {
  adminEventNames,
  apiMethods,
  editEventDecimalValue,
  priceRegex,
} from "../../../constant/constants.jsx";
import {
  getDaysAndHours,
  getTimeStamp,
  handleTargetDateChange,
  modifyDate,
} from "../../../helpers/commonTimeFunctions.jsx";
import { eventFormErrorData } from "../../../constant/structuralContants.jsx";
import { contractEvents } from "../../../utils/contractHelpers.jsx";
import useGenerateIpfsUrl from "../../../hooks/useGetIpfsUrl.jsx";
import { getCrypto } from "../../../helpers/commonApiHelpers.jsx";
import {
  checkEventEditable,
  convertLeadingZeros,
  errorSetterFunction,
  getCurrencyIconUrl,
} from "../../../helpers/commonHelpers.jsx";
import { customToast } from "../../Toast/toast.jsx";
import { Path } from "../../Routing/Constant/RoutePaths.jsx";
import UseGetApi from "../../../hooks/useGetApi.jsx";
import { apiUrls } from "../../../constant/apiConstants.jsx";
import CreateEventComp from "./CreateEventComp.jsx";

function CreateEvent() {
  const defTargetDate = modifyDate(new Date(), 1, "addHours"); // min 1 hour event duration
  const defClosureDate = modifyDate(defTargetDate, 5, "minusMins"); // 5min before bet close

  const eventFormData = {
    crypto: "",
    cryptoIconUrl: "",
    price: "",
    targetDate: defTargetDate,
    betClosureTime: defClosureDate,
    eventDurationDays: 0,
    eventDurationHours: 1,
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  const eventId = state?.eventId;
  const [disable, setDisable] = useState(false);
  const [cryptoList, setCryptoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(eventFormErrorData);
  const [formData, setFormData] = useState(eventFormData);
  const [showEventModal, setShowEventModal] = useState(false);
  const [publishDisable, setPublishDisable] = useState(false);
  const [showSuccesstModal, setShowSuccessModal] = useState(false);
  const { ipfsUrl, generateIpfsUrl, setIpfsUrl } = useGenerateIpfsUrl();
  const [eventFetching, setEventFetching] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [minDate, setMinDate] = useState({
    target: defTargetDate,
    closure: defClosureDate,
  });

  useEffect(() => {
    setMinDate({ target: defTargetDate, closure: defClosureDate });
  }, []);

  // Close Preview Modal
  const closeEventModal = () => {
    setShowEventModal(false);
  };

  // Open Preview Modal
  const openSuccessModal = () => {
    setShowSuccessModal(true);
    setShowEventModal(false);
  };

  // Close Success Modal
  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    navigate(Path.EVENT);
  };

  // Form OnChange Handler
  const onChangeHandle = (e) => {
    const { name, value, iconUrl } = e.target;
    let updatedFormData = { ...formData };

    switch (name) {
      case "crypto":
        updatedFormData[name] = value;
        updatedFormData["cryptoIconUrl"] = iconUrl;
        break;
      case "price":
        // Convert leading zeros
        const convertedValue = convertLeadingZeros(value);
        if (priceRegex.test(convertedValue) || convertedValue === "") {
          updatedFormData[name] = convertedValue;
        }
        break;
      case "targetDate":
        if (!value) {
          updatedFormData[name] = "";
          updatedFormData["eventDurationDays"] = 0;
        } else {
          updatedFormData = handleTargetDateChange(value, updatedFormData);
        }
        break;
      default:
        updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
  };

  // Open Preview Modal & Generate Ipfs Url
  const handlePreviewClick = () => {
    let newErr = errorSetterFunction(formData);
    setError(newErr);
    const hasErrors = Object.values(newErr).some((error) => error !== "");

    if (!hasErrors) {
      setIpfsUrl("");
      !isEditable && generateIpfsUrl(formData);
      setShowEventModal(true);
    }
  };

  // Create / Edit Event Handler
  const createEditEvent = async () => {
    try {
      setIsLoading(true);
      setPublishDisable(true);
      let res;
      if (isEditable) {
        // edit event
        res = await contractEvents({
          eventName: adminEventNames.editEvent,
          expiryTime: getTimeStamp(formData?.targetDate), // To convert date into timestamp
          price: Math.ceil(formData?.price * 10 ** editEventDecimalValue),
          eventId: eventId,
        });
      } else {
        // create event
        res = await contractEvents({
          eventName: adminEventNames.registerEvent,
          expiryTime: getTimeStamp(formData?.targetDate), // To convert date into timestamp
          ipfs: ipfsUrl,
        });
      }
      if (res?.length) {
        openSuccessModal();
        setFormData({ ...eventFormData, crypto: cryptoList[0]?.symbol });
      }
    } catch ({ message }) {
      customToast.error(message);
    } finally {
      setIsLoading(false);
      setPublishDisable(false);
    }
  };

  // Get Event Details via Event Id
  const getEventDetails = async () => {
    setEventFetching(true);
    try {
      if (cryptoList?.length) {
        const { data } = await UseGetApi(
          apiUrls.getEventDetails(eventId),
          apiMethods.GET
        );
        let event = data?.data?.eventsDetails[0];
        // Check if the event has expired
        const isEditAllowed =
          checkEventEditable(state?.createdAt) && event?.noBetYet;
        setIsEditable(isEditAllowed);
        if (!isEditAllowed) {
          // If expired, clear form data
          setFormData(eventFormData);
        } else {
          // If not expired, set form data
          const { gapInDays, gapInHours } = getDaysAndHours(
            event?.targetDateTime
          );
          // it will get currency icon url on basis of currency symbol
          const icon = getCurrencyIconUrl(
            cryptoList,
            event?.currencyDetails?.symbol
          );
          const targetDateValue = dayjs(event?.targetDateTime * 1000);
          // set form data
          setFormData({
            crypto: event?.currencyDetails?.symbol,
            cryptoIconUrl: icon?.iconUrl,
            price: event?.priceLevel,
            targetDate: targetDateValue,
            betClosureTime:
              handleTargetDateChange(targetDateValue)?.betClosureTime,
            eventDurationDays: gapInDays,
            eventDurationHours: gapInHours,
          });
        }
        setEventFetching(false);
      }
    } catch ({ message }) {
      customToast.error(message);
      setEventFetching(false);
    }
  };

  useEffect(() => {
    // fetch crypto currency listing
    const fetchData = async () => {
      if (isEditable) await getCrypto(setCryptoList);
      else await getCrypto(setCryptoList, true);
    };
    fetchData();
  }, [isEditable]);

  // Set Errors for form
  useEffect(() => {
    const hasError = Object.values(error).some((errorMessage) => errorMessage);
    setDisable(hasError);
  }, [error]);

  // Handle Publish Button Disable based on Ipfs Url
  useEffect(() => {
    if (!isEditable && !eventId?.length) {
      setPublishDisable(!ipfsUrl);
    }
  }, [ipfsUrl]);

  // Handle Create & Edit Event Button Disable based on Ipfs Url
  useEffect(() => {
    if (disable) {
      let newErr = errorSetterFunction(formData);
      setError(newErr);
    }
  }, [disable, formData]);

  // Call get Event details api based on eventId length
  useEffect(() => {
    if (eventId) {
      getEventDetails();
    }
  }, [eventId, cryptoList]);

  return (
    <CreateEventComp
      handlePreviewClick={handlePreviewClick}
      createEditEvent={createEditEvent}
      onChangeHandle={onChangeHandle}
      closeEventModal={closeEventModal}
      closeSuccessModal={closeSuccessModal}
      minDate={minDate}
      eventFetching={eventFetching}
      showSuccesstModal={showSuccesstModal}
      publishDisable={publishDisable}
      showEventModal={showEventModal}
      isLoading={isLoading}
      formData={formData}
      cryptoList={cryptoList}
      isEditable={isEditable}
      error={error}
      disable={disable}
      setIsLoading={setIsLoading}
    />
  );
}

export default CreateEvent;
