import React from "react";
import PropTypes from "prop-types";
import "./SidebarCard.scss";
import { formatMillionNumber } from "../../helpers/commonHelpers";

function SidebarCard({ imageSrc, heading, paragraph, comp }) {
  const formattedHeading =
    comp === "dashboard" ? formatMillionNumber(heading) : heading;

  return (
    <div className={`sidebarCard`}>
      {imageSrc}
      <p className="sidebarCard__text">{paragraph}</p>
      <h2 className="sidebarCard__heading" title={heading || 0}>
        {formattedHeading}
      </h2>
    </div>
  );
}

SidebarCard.propTypes = {
  imageSrc: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  comp: PropTypes.string.isRequired,
};

export default SidebarCard;
