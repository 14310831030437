import React from "react";
import { Table } from "antd";
function ActivityTable() {
  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      key: "srNo",
    },
    {
      title: "MARKET",
      dataIndex: "market",
      key: "market",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Bought",
      dataIndex: "bought",
      key: "bought",
    },
    {
      title: "Bet",
      dataIndex: "bet",
      key: "bet",
    },
    {
      title: "Date & Time",
      dataIndex: "dateTime",
      key: "dateTime",
    },
  ];

  const data = [];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      className="commontable"
      overflow="auto"
    />
  );
}

export default ActivityTable;
