import { message, Skeleton, Table } from "antd";
import "./Token.scss";
import CustomModal from "../../customModal/CustomModal";
import { useEffect, useState } from "react";
import AddToken from "./AddToken";
import ConfirmToken from "./ConfirmToken";
import UseGetApi from "../../../hooks/useGetApi";
import { apiUrls } from "../../../constant/apiConstants";
import { tokenListCol } from "../../../constant/structuralContants";
import Header from "./Header";

function Token() {
  const initValue = {
    isSubmitting: false,
    name: null,
    symbol: null,
    file: null,
  };

  const [addTokenModal, setaddTokenModal] = useState(false);
  const [confirmTokenModal, setConfirmTokenModal] = useState(false);
  const [metaData, setMetaData] = useState({
    statuskey: "0",
    sortkey: "0",
    total: 0,
    data: [],
    page: 1,
    limit: 10,
    sort: "desc",
    status: "all",
    isLoading: true,
  });
  const [formData, setFormData] = useState(initValue);
  const [loading, setLoading] = useState(false);

  const toggleAddModal = (fetchList) => {
    fetchList === true && getTokenList();
    setFormData(initValue);
    setaddTokenModal(!addTokenModal);
  };

  const openConfirmModal = (symbol, action, key) => {
    setMetaData((prev) => ({
      ...prev,
      selectedCoin: symbol,
      action,
      key,
    }));
    setConfirmTokenModal(true);
  };

  const onRemove = () => {
    updateToken();
    onRemoveModalClose();
  };

  const onRemoveModalClose = () => {
    setConfirmTokenModal(null);
  };

  const getStatus = (record) => {
    switch (record?.status) {
      case 0:
        return {
          currentStatus: "Inactive",
          currentStatusColor: "red",
          action: "Active",
          key: 1,
        };
      case 1:
        return {
          currentStatus: "Active",
          currentStatusColor: "green",
          action: record?.noOfActiveEvents === 0 ? "Deactivate" : "Pause",
          key: record?.noOfActiveEvents === 0 ? 0 : 2,
        };
      case 2:
        return {
          currentStatus: "Paused",
          currentStatusColor: "orange",
          action: record?.noOfActiveEvents === 0 ? "Deactivate" : "Active",
          key: record?.noOfActiveEvents === 0 ? 0 : 1,
        };
      default:
        break;
    }
  };

  const getTokenList = async () => {
    try {
      setMetaData({ ...metaData, isLoading: true });
      const response = await UseGetApi(
        apiUrls?.getTokenList(
          metaData?.page,
          metaData?.limit,
          metaData?.sort,
          metaData?.status
        )
      );
      if (response?.data?.status === 200) {
        const tempData = [];
        response?.data?.data?.tokenList.map((item, index) => {
          const serial = (metaData?.page - 1) * metaData?.limit + index + 1;
          tempData.push({
            key: index + 1,
            sno: serial,
            tokenName: item?.name,
            symbol: item?.symbol,
            image: item?.iconUrl,
            status: item?.status,
            addedDate: item?.createdAt,
            noOfActiveEvents: item?.noOfActiveEvents,
          });
        });
        setMetaData((prev) => {
          return {
            ...prev,
            data: [...tempData],
            total: response?.data?.data?.total,
            isLoading: false,
          };
        });
      }
      setMetaData((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateToken = async () => {
    const response = await UseGetApi(
      apiUrls?.updateToken,
      "PUT",
      {
        tokenName: metaData?.selectedCoin,
        status: metaData?.key,
      },
      {
        "Content-Type": "multipart/form-data",
      }
    );

    if (response?.data?.status === 200) {
      message.success(response?.data?.message);
      getTokenList();
    }
  };

  const handlePageChange = (page) => {
    setMetaData({
      ...metaData,
      page,
    });
  };

  useEffect(() => {
    getTokenList();
  }, [metaData?.page, metaData?.sort, metaData?.status]);

  return (
    <div className="token">
      <Header
        metaData={metaData}
        setMetaData={setMetaData}
        toggleAddModal={toggleAddModal}
      />
      {/* table */}
      {metaData?.isLoading ? (
        <div className="no-events-found">
          <Skeleton active paragraph={{ rows: 5 }} />
        </div>
      ) : (
        <Table
          columns={tokenListCol(getStatus, openConfirmModal)}
          dataSource={metaData?.data}
          className="commontable"
          pagination={
            metaData?.total > metaData?.limit
              ? {
                  current: metaData?.page,
                  pageSize: metaData?.limit,
                  total: metaData?.total,
                  onChange: handlePageChange,
                  showSizeChanger: false,
                }
              : false
          }
        />
      )}
      {/* add modal */}
      <CustomModal
        visible={addTokenModal}
        onClose={toggleAddModal}
        closable={!loading}
        title="Add New Token"
        content={
          <AddToken
            formData={formData}
            setFormData={setFormData}
            setLoading={setLoading}
            loading={loading}
            onSubmit={() => toggleAddModal(true)}
          />
        }
        width={514}
      />
      {/* remove modal */}
      <CustomModal
        visible={confirmTokenModal}
        onClose={onRemoveModalClose}
        title={false}
        content={
          <ConfirmToken
            onClose={onRemoveModalClose}
            onRemove={onRemove}
            action={metaData?.action}
            symbol={metaData?.selectedCoin}
          />
        }
        width={514}
      />
    </div>
  );
}

export default Token;
