import React from "react";
import "./CreateEvent.scss";
import EventSelect from "./EventSelect/EventSelect.jsx";
import PreviewEvent from "../../CommonDetailcard/PreviewEvent.jsx";
import InputCustom from "../../InputCustom/InputCustom.jsx";
import DatePicker from "../../DatePicker/DatePicker.jsx";
import CustomModal from "../../customModal/CustomModal.jsx";
import ButtonCustom from "../../ButtonCustom/ButtonCustom.jsx";
import SuccessFul from "./SuccessFul.jsx";
import {
  createEventSuccess,
  editEventSuccess,
  eventCreationDateFormat,
} from "../../../constant/constants.jsx";

import { Skeleton } from "antd";

function CreateEventComp({
  handlePreviewClick,
  createEditEvent,
  onChangeHandle,
  closeEventModal,
  closeSuccessModal,
  minDate,
  eventFetching,
  showSuccesstModal,
  publishDisable,
  showEventModal,
  isLoading,
  formData,
  cryptoList,
  isEditable,
  error,
  disable,
  setIsLoading,
}) {
  return eventFetching ? (
    <Skeleton active paragraph={{ rows: 5 }} />
  ) : (
    <>
      <div className="createEvent">
        <div className="createEvent_top">
          <div className="createEvent_top_crypto date_error">
            {/* Choose Currency */}
            <label>Choose Crypto</label>
            <EventSelect
              onChangeHandle={onChangeHandle}
              name="crypto"
              value={formData?.crypto}
              cryptoList={cryptoList}
              isDisable={isEditable}
            />
            {error?.crypto ? (
              <p className="error_red">{error?.crypto}</p>
            ) : null}
          </div>

          {/* Choose Price */}
          <div className="createEvent_info date_error">
            <InputCustom
              label="Price Level"
              placeholder="Enter Price"
              regularInput
              name="price"
              onChange={onChangeHandle}
              value={formData?.price}
            />
            <p className="usdt">USDT</p>
            {error?.price ? <p className="error_red">{error?.price}</p> : null}
          </div>
        </div>

        {/* Target Date & Time */}
        <div className="createEvent_dateSecton">
          <div className="date_error width-50">
            <DatePicker
              key={minDate} // Force re-render by updating key
              label="Target Date & Time "
              name="targetDate"
              onChangeHandle={onChangeHandle}
              defaultValue={minDate?.target}
              minDate={minDate?.target}
              value={formData?.targetDate}
            />
            {error?.targetDate ? (
              <p className="error_red">{error?.targetDate}</p>
            ) : null}
          </div>

          {/* Event Durations */}
          <div className="createEvent_dateSecton_duration">
            <div className="createEvent_info">
              <InputCustom
                label="Event Duration"
                placeholder="00"
                regularInput
                name="eventDurationDays"
                onChange={onChangeHandle}
                value={formData?.eventDurationDays}
                disabled={true}
              />
              <p className="usdt">
                {formData?.eventDurationDays > 1 ? "Days" : "Day"}
              </p>
            </div>
            <div className="createEvent_info">
              <InputCustom
                placeholder="00"
                label={<span className="empty">label</span>}
                regularInput
                name="eventDurationHours"
                onChange={onChangeHandle}
                value={formData?.eventDurationHours}
                disabled={true}
              />
              <p className="usdt">
                {formData?.eventDurationHours > 1 ? "Hours" : "Hour"}
              </p>
            </div>
          </div>
        </div>

        {/* Betting Closure Time */}
        <div>
          <DatePicker
            label="Betting Closure Date & Time"
            name="betClosureTime"
            defaultValue={minDate?.closure}
            minDate={minDate?.closure}
            value={formData?.betClosureTime}
            disable={true}
            format={eventCreationDateFormat}
          />
        </div>

        {/* Edit / Create Event Button */}
        <div className="createEvent_btn">
          <ButtonCustom
            label={isEditable ? "Edit Event" : "Create Event"}
            onClick={handlePreviewClick}
            isDisabled={disable}
          />
        </div>
      </div>

      {/* Preview Modal */}
      <CustomModal
        className="previewModal"
        visible={showEventModal}
        onClose={closeEventModal}
        title="Preview Your Event"
        content={
          <PreviewEvent
            onClick={createEditEvent}
            data={formData}
            closeEventModal={closeEventModal}
            disable={publishDisable}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isEditable={isEditable}
          />
        }
        width={540}
      />

      {/* Success Modal */}
      <CustomModal
        visible={showSuccesstModal}
        onClose={closeSuccessModal}
        title={false}
        content={
          <SuccessFul
            close={closeSuccessModal}
            content={isEditable ? editEventSuccess : createEventSuccess}
          />
        }
        width={520}
      />
    </>
  );
}

export default CreateEventComp;
