import { env } from "./envConstant";

export const minPriceLevel = 0.1;
export const maxPriceLevel = 1000000;

export const addressRegex = /^[a-zA-Z0-9]+$/;
export const alphabetsOnly = /^[A-Za-z]{1,100}$/;
export const alphabets = /^[a-zA-Z]+$/;
export const regex = /^(50(\.0{0,2})?|([0-4]?\d(\.\d{0,2})?))?$/;
export const eventRegex = /^(100(\.0{0,2})?|([0-9]?\d(\.\d{0,2})?))?$/;
export const priceRegex =
  /^$|^(([1-9]?\d{0,5}|1000000)(\.\d{0,7})?|([0]\.\d{1,7}))$/;
export const mobileDevicesRegex =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

export const EventDateFormat = "Do MMMM YYYY, HH:mm";
export const targetClosureDateFormat = "YYYY-MM-DD HH:mm";
export const DatePickerFormat = "YYYY-MM-DD | HH:00:00";
export const eventCreationDateFormat = "YYYY-MM-DD | HH:mm:00";
export const InTableDateFormat = "YYYY-MM-DD | HH:mm:ss";

export const tableLimit = 10;
export const decimalValue = 18;

export const messages = {
  //Metamask
  Switch_Error: "Unable To Switch: ",
  Wrong_Wallet_Address: "Wrong Wallet Address",
  Network_Add_Error: `Error adding ${env.chainName} network:`,
  Same_Network: `You are already on the ${env.chainName} network.`,
  Network_Added: `You have successfully switched to ${env.chainName} network.`,
  Retrive_Meta_Account: `Cannot retrieve any account. Please refresh the browser`,
  Install_Wallet: `Please install the Metamask. If installed, refresh your browser.`,
  Successfully_Switched: `You have successfully switched to ${env.chainName} network.`,
  faultImgRaiseDispute: "Please upload image of size less than 5MB.",
  faultImg: "Please upload image of size less than 1.2MB.",
};

export const errorCodes = {
  addChain: 4902,
  isMobileAddChain: -32605,
};

export const adminEventNames = {
  getEventFee: "get_creation_fee",
  registerEvent: "register_event",
  setPlatformFee: "set_platform_fee",
  getPlatformFee: "get_platform_fee",
  setEventFees: "set_creation_fee_event",
  editEvent: "edit_expiration_time_event",
  readPoolAmount: "read_pool_amount_event",
  getTotalRewards: "get_admin_total_reward",
  addFallbackWallet: "add_fallback_wallets",
  getFallbackWallets: "get_fallback_wallets",
  getOldEventsPlatformFee: "get_platform_fee_event",
};

export const apiMethods = {
  GET: "get",
  PUT: "put",
  POST: "post",
  DELETE: "delete",
};

export const platformFeeTooltip =
  "Ensures that platform remains operational and updated.";
export const editEventSuccess = "Your event has been edited successfully";
export const createEventSuccess = "Your event has been created successfully";

export const priceDecimalValue = 18;
export const editEventDecimalValue = 7;
