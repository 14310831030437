import { useState } from "react";
import { BrowserProvider } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { setLoggedIn } from "../utils/walletHelpers";
import UseGetApi from "./useGetApi";
import {
  decimalValue,
  errorCodes,
  messages,
  mobileDevicesRegex,
} from "../constant/constants";
import { useNavigate } from "react-router-dom";
import { env } from "../constant/envConstant";
import { customToast } from "../Common/Toast/toast";
import { apiUrls } from "../constant/apiConstants";
import { useIsLoggedIn } from "../context/loggedInContext";
import { Path } from "../Common/Routing/Constant/RoutePaths";

const useWalletConnection = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const { setLoggedInValue } = useIsLoggedIn();
  const [isLoading, setIsLoading] = useState(false);

  const getProvider = async (webProvider, address) => {
    try {
      const providers = new BrowserProvider(webProvider);
      const payload = await openSignModal(providers, address);
      return payload;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const addNetwork = async () => {
    setIsLoading(true);
    try {
      const provider = await detectEthereumProvider();
      const userAgent = window.navigator.userAgent;
      // check if user is on mobile device or not
      const isMobileDevice = mobileDevicesRegex.test(userAgent);
      // if user is on mobile device and it didn't find metamask navigate
      if (isMobileDevice && !provider) {
        try {
          const metamaskAppDeepLink =
            "dapp://" + window.location.origin.slice(8);
          window.location.href = metamaskAppDeepLink;
          setTimeout(() => {
            customToast.error(messages.Install_Wallet);
            setIsLoading(false);
          }, 5000);
        } catch (error) {
          window.location.href =
            env.deepLinkUrl + window.location.origin.slice(8);
        }
        return; // Exit function after redirection
      }
      // if user is on desktop browser & didn't find metamask
      if (!provider && !isMobileDevice) {
        return customToast.error(messages.Install_Wallet);
      }
      const address = window.ethereum.selectedAddress;
      const chainId = await provider.request({ method: "eth_chainId" });
      const ChainId = env.chainId;

      if (!chainId) {
        return customToast.info(messages.Retrive_Meta_Account);
      }

      if (chainId === ChainId) {
        await getProvider(provider, address);
      } else {
        try {
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ChainId }],
          });
          await getProvider(provider, address);
        } catch (switchError) {
          //if user is on mobile device check for different error code
          if (isMobileDevice) {
            const errorCode = switchError?.data?.originalError?.code;
            if (
              (errorCode && errorCode === errorCodes?.addChain) ||
              switchError?.code == errorCodes?.isMobileAddChain
            ) {
              addChain(ChainId, provider, address);
            }
          } else {
            // if user is on desktop
            if (switchError?.code === errorCodes?.addChain) {
              addChain(ChainId, provider, address);
            } else {
              customToast.error(
                `${messages?.Switch_Error}, ${switchError?.message}`
              );
            }
          }
        }
      }
    } catch ({ message }) {
      setIsLoading(false);
      customToast.error(message);
    }
  };

  const addChain = async (ChainId, provider, address) => {
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: ChainId,
            chainName: env.chainName,
            rpcUrls: [env.metamaskRpcUrl],
            blockExplorerUrls: [env.explorerNavigationUrl],
            nativeCurrency: {
              name: env.currencyName,
              symbol: env.currencyName,
              decimals: decimalValue,
            },
          },
        ],
      });
      await getProvider(provider, address);
    } catch ({ message }) {
      setIsLoading(false);
      customToast.error(`${messages.Network_Add_Error}, ${message}`);
    }
  };
  const openSignModal = async (provider, address) => {
    try {
      const signer = await provider.getSigner();
      const signature = await signer.signMessage(
        address ? address : signer?.address
      );
      setIsDisabled(true);
      return await verifySignMessage(
        signature,
        address ? address : signer?.address
      );
    } catch ({ message }) {
      setIsLoading(false);
      return message;
    }
  };

  const verifySignMessage = async (signature, address) => {
    try {
      setIsLoading(true);
      const { data } = await UseGetApi(apiUrls.connectWallet, "post", {
        wallet_address: address,
        signature_key: signature,
      });
      if (address && data?.data?.walletAddress?.length) {
        setLoggedIn(address);
        setLoggedInValue(address);
        navigate(Path?.DASHBOARD);
        return data;
      }
    } catch (error) {
      return customToast.error(messages.Wrong_Wallet_Address);
    } finally {
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  return {
    isDisabled,
    isLoading,
    addNetwork,
  };
};

export default useWalletConnection;
