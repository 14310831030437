import { constructQueryString } from "../helpers/commonHelpers";
import { env } from "./envConstant";

export const apiUrls = {
  logout: "/admin/logout",
  createEvent: "/admin/eventCreate",
  connectWallet: "/admin/adminLogin",
  getTotalUsers: "/user/getTotalUser",
  getTotalEvents: "/user/getTotalEvents",
  getTotalVolume: "/user/getTotalVolume",
  setPlatformFee: "/admin/setPlateformFees",
  getCryptoListing: (params) =>
    `/currency/getTopMarket${constructQueryString(params)}`,
  getTotalDisputes: "/admin/getTotalDispute",
  getTotalStakedAmount: "/admin/amountStaked",
  addWalletsAndNames: "/admin/addFallBackWallet",
  getFallbackWallets: "/admin/getFallBackWallets",
  setEventCreationFee: "/admin/eventCreationFees",
  editWalletsAndNames: "/admin/editFallBackWallet",
  getTotalCreators: "/admin/getTotalEventCreators",
  getTotalTransactions: "/admin/getTotalTransaction",
  generateUrlForEventCreation: "/contract/createIpfsUrl",
  getTokenList: (page, limit, sort, status) =>
    `/admin/getTokenList?page=${page}&limit=${limit}&sort=${sort}&status=${status}`,
  addToken: () => "/admin/addToken",
  updateToken: "/admin/updateToken",
  getEventDetails: (eventId) => `/admin/getEventDetails?eventId=${eventId}`,

  //apis with pagination
  getUsersCreatorsListing: (page, limit, tab) => {
    return tab === "0"
      ? `/admin/getUser?page=${page || undefined}&limit=${limit || undefined}`
      : tab === "1"
      ? `/admin/getEventsCreators?page=${page || undefined}&limit=${
          limit || undefined
        }`
      : null;
  },
  getClosedEvents: (page, limit, tab, filter) => {
    return `/admin/getClosedPosition?page=${page || undefined}&limit=${
      limit || undefined
    }&status=${tab || "1"}&filter=${filter || "all"}`; //0 = closed status, 1 = open status
  },
  getDisputes: (page, limit) => {
    return `/admin/getDisputeRaise?page=${page}&limit=${limit}`;
  },
  verifyToken: (params) =>
    `${env.cmcKeyBaseURL}${constructQueryString(params)}`,
};
